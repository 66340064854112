import { config } from '@tamagui/config/v2-native';
import { appTokens } from './tokens';
import { generateDarkTheme, generateLightTheme } from './helpers';
import { appColorPalettes, supplementaryColorPalettes } from './tokens/colors';

const lightTheme = {
  ...config.themes.light,
  background: appTokens.color.backgroundLight,
  onBackground: appTokens.color.onBackgroundLight,
  /* global themes */
  ...generateLightTheme('primary', appColorPalettes.primary),
  ...generateLightTheme('secondary', appColorPalettes.secondary),
  ...generateLightTheme('tertiary', appColorPalettes.tertiary),
  /* supplementary themes */
  ...generateLightTheme('success', supplementaryColorPalettes.success),
  ...generateLightTheme('info', supplementaryColorPalettes.info),
  ...generateLightTheme('warning', supplementaryColorPalettes.warning),
};

const darkTheme = {
  ...config.themes.dark,
  background: appTokens.color.backgroundDark,
  onBackground: appTokens.color.onBackgroundDark,
  /* global themes */
  ...generateDarkTheme('primary', appColorPalettes.primary),
  ...generateDarkTheme('secondary', appColorPalettes.secondary),
  ...generateDarkTheme('tertiary', appColorPalettes.tertiary),
  /* supplementary themes */
  ...generateDarkTheme('success', supplementaryColorPalettes.success),
  ...generateDarkTheme('info', supplementaryColorPalettes.info),
  ...generateDarkTheme('warning', supplementaryColorPalettes.warning),
};

const componentThemes = {
  /* Button component themes */
  dark_Button: {
    ...config.themes.dark_Button,
    background: darkTheme.primary,
    backgroundHover: darkTheme.primary,
    backgroundPress: darkTheme.primaryPress,
    color: darkTheme.onPrimary,
  },
  dark_secondary_Button: {
    ...config.themes.dark_Button,
    background: darkTheme.secondary,
    backgroundHover: darkTheme.secondary,
    backgroundPress: darkTheme.secondaryPress,
    color: darkTheme.onSecondary,
  },
  light_Button: {
    ...config.themes.light_Button,
    background: lightTheme.primaryContainer,
    backgroundHover: lightTheme.primaryContainer,
    backgroundPress: lightTheme.primaryContainerPress,
    color: lightTheme.onPrimaryContainer,
  },
  light_secondary_Button: {
    ...config.themes.light_Button,
    background: lightTheme.secondaryContainer,
    backgroundHover: lightTheme.secondaryContainer,
    backgroundPress: lightTheme.secondaryContainerPress,
    color: lightTheme.onSecondaryContainer,
  },
  /* Card component themes */
  dark_success_Card: {
    ...config.themes.dark_Card,
    background: darkTheme.success,
    backgroundHover: darkTheme.success,
    backgroundPress: darkTheme.successPress,
    color: darkTheme.onSuccess,
  },
  light_success_Card: {
    ...config.themes.light_Card,
    background: lightTheme.successContainer,
    backgroundHover: lightTheme.successContainer,
    backgroundPress: lightTheme.successContainerPress,
    color: lightTheme.onSuccessContainer,
  },
  dark_info_Card: {
    ...config.themes.dark_Card,
    background: darkTheme.info,
    backgroundHover: darkTheme.info,
    backgroundPress: darkTheme.infoPress,
    color: darkTheme.onInfo,
  },
  light_info_Card: {
    ...config.themes.light_Card,
    background: lightTheme.infoContainer,
    backgroundHover: lightTheme.infoContainer,
    backgroundPress: lightTheme.infoContainerPress,
    color: lightTheme.onInfoContainer,
  },
  dark_warning_Card: {
    ...config.themes.dark_Card,
    background: darkTheme.warning,
    backgroundHover: darkTheme.warning,
    backgroundPress: darkTheme.warningPress,
    color: darkTheme.onWarning,
  },
  light_warning_Card: {
    ...config.themes.light_Card,
    background: lightTheme.warningContainer,
    backgroundHover: lightTheme.warningContainer,
    backgroundPress: lightTheme.warningContainerPress,
    color: lightTheme.onWarningContainer,
  },
};

export const appThemes = {
  ...config.themes,
  dark: darkTheme,
  dark_secondary: darkTheme,
  light: lightTheme,
  light_secondary: lightTheme,
  ...componentThemes,
};

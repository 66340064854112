import React from 'react';
import { FacesHappyOpenMouth } from './mouths/happy-open';
import { FacesClosedEyes } from './eyes/closed';
import { FacesBase } from './base';

export function FacesHappy() {
  return (
    <FacesBase>
      <FacesClosedEyes />
      <FacesHappyOpenMouth />
    </FacesBase>
  );
}

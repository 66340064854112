import * as Colyseus from 'colyseus.js';
import { UserCredential, signInAnonymously } from 'firebase/auth';
import { auth } from './firebase';
import { AvatarKey } from '../avatars';

const API_BASE = (() => {
  const scheme = process.env.COLYSEUS_API_SECURE === 'true' ? 'https' : 'http';
  const port = process.env.COLYSEUS_API_PORT;

  let baseUrl = `${scheme}://${process.env.COLYSEUS_API_HOST}`;

  if (port) {
    baseUrl = `${baseUrl}:${port}`;
  }
  return baseUrl;
})();

const isSecure = process.env.COLYSEUS_API_SECURE === 'true';
const colyseusClient = new Colyseus.Client({
  hostname: process.env.COLYSEUS_API_HOST || '',
  secure: isSecure,
  port: Number(process.env.COLYSEUS_API_PORT) || isSecure ? 443 : 80,
});

type ReconnectToken = {
  roomId: string;
  token: string;
};

export type ColyseusProfile = {
  username: string;
  avatar: AvatarKey;
  avatarColor: string;
};

class ColyseusHelper {
  credentials: UserCredential | undefined;
  reconnectionTokens: ReconnectToken[] = [];

  getReconnectToken(roomId: string) {
    return this.reconnectionTokens.find((rcToken) => rcToken.roomId === roomId)
      ?.token;
  }

  setReconnectToken(roomId: string, token: string) {
    if (this.getReconnectToken(roomId)) {
      this.reconnectionTokens = this.reconnectionTokens.filter(
        (rcToken) => rcToken.roomId !== roomId
      );
    }
    this.reconnectionTokens.push({ roomId, token });
  }

  async getCredentials() {
    if (this.credentials) {
      return this.credentials;
    }

    const userCredential = await signInAnonymously(auth);
    this.credentials = userCredential;
    return userCredential;
  }

  async createLobby() {
    return await fetch(`${API_BASE}/createroom`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: '{}',
    }).then(async (res) => (await res.json()) as Colyseus.RoomAvailable);
  }

  async getLobbyByCode(roomCode: string) {
    return await fetch(`${API_BASE}/lookuproom?roomCode=${roomCode}`).then(
      async (res) => {
        if (!res.ok) {
          throw new Error('Unable to join room');
        }
        return (await res.json()) as Colyseus.RoomAvailable;
      }
    );
  }

  async joinMatchById(profile: ColyseusProfile, matchId: string) {
    const credentials = await this.getCredentials();

    if (process.env.ENABLE_HEALTH_PING === 'true') {
      setInterval(async () => {
        await fetch(`${API_BASE}/__healthcheck`);
      }, 2 * 1000 * 60);
    }

    const reconnectToken = this.getReconnectToken(matchId);

    let room: Colyseus.Room;

    // join game or reconnect
    if (!reconnectToken) {
      room = await colyseusClient.joinById(matchId, {
        accessToken: await credentials.user.getIdToken(),
        ...profile,
      });
    } else {
      room = await colyseusClient.reconnect(reconnectToken);
    }

    this.setReconnectToken(matchId, room.reconnectionToken);

    return room;
  }
}

export default new ColyseusHelper();

import {
  AvatarColorOption,
  AvatarSet,
  AvatarSetDetails,
} from '../../@types/avatars';
import { getAvatarList } from '../helpers';
import { FacesAlien } from './alien';
import { FacesHappy } from './happy';
import { FacesKissy } from './kissy';
import { FacesSadGuy } from './sad-guy';

export type FacesAvatar =
  | 'faces-alien'
  | 'faces-sadguy'
  | 'faces-happy'
  | 'faces-kissy';

const colorOptions: AvatarColorOption[] = [
  {
    value: '#fffc5c',
    name: 'Yellow',
  },
  {
    value: '#5cff8f',
    name: 'Green',
  },
  {
    value: '#5cfaff',
    name: 'Blue',
  },
  {
    value: '#ff5cd6',
    name: 'Pink',
  },
  {
    value: '#be5cff',
    name: 'Purple',
  },
];

const avatars: AvatarSet<FacesAvatar> = {
  'faces-alien': {
    name: 'Alien',
    render: FacesAlien,
  },
  'faces-sadguy': {
    name: 'Sad Guy',
    render: FacesSadGuy,
  },
  'faces-happy': {
    name: 'Happy',
    render: FacesHappy,
  },
  'faces-kissy': {
    name: 'Kissy',
    render: FacesKissy,
  },
};

export const FacesSet: AvatarSetDetails<FacesAvatar> = {
  name: 'Faces',
  avatars,
  avatarList: getAvatarList(avatars),
  colorOptions,
};

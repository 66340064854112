import React from 'react';
import { Avatar, AvatarProps } from 'tamagui';
import { AvatarKey, AvatarList } from '../../avatars';

type Props = {
  avatar: AvatarKey;
  size?: 'sm' | 'md' | 'lg';
  color?: string;
} & AvatarProps;

const AVATAR_SIZES = {
  sm: '$4',
  md: '$6',
  lg: '$8',
};

export function PlayerAvatar(props: Props) {
  const size = AVATAR_SIZES[props.size || 'sm'];

  return (
    <Avatar
      circular
      {...props}
      size={size}
      backgroundColor={props.color}
      style={props.style}
    >
      {AvatarList[props.avatar].render()}
    </Avatar>
  );
}

import { BotttsBase } from './base';
import { BotttsRoundVisorEyes } from './eyes/round-visor';
import { BotttsFourVentsMouth } from './mouths/four-vents';
import React from 'react';

export function BotttsFluffy() {
  return (
    <BotttsBase>
      <BotttsRoundVisorEyes />
      <BotttsFourVentsMouth />
    </BotttsBase>
  );
}

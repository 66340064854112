import { Circle, G, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsRoundVisorOpenEyes() {
  return (
    <G transform="translate(8 20)">
      <Rect
        y="11"
        width="104"
        height="34"
        rx="17"
        fill="#000"
        fillOpacity=".8"
      />
      <Circle cx="29" cy="28" r="13" fill="#F1EEDA" />
      <Circle cx="75" cy="28" r="13" fill="#F1EEDA" />
      <Rect
        x="24"
        y="23"
        width="10"
        height="10"
        rx="2"
        fill="#000"
        fillOpacity=".8"
      />
      <Rect
        x="70"
        y="23"
        width="10"
        height="10"
        rx="2"
        fill="#000"
        fillOpacity=".8"
      />
    </G>
  );
}

import { Path, G, Mask, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsRobocopVisorEyes() {
  return (
    <G transform="translate(8 20)">
      <Rect
        x="7"
        y="16"
        width="91"
        height="16"
        rx="4"
        fill="#000"
        fillOpacity=".8"
      />
      <Mask
        id="eyesRobocop-a"
        maskUnits="userSpaceOnUse"
        x="7"
        y="16"
        width="91"
        height="16"
      >
        <Rect x="7" y="16" width="91" height="16" rx="4" fill="#fff" />
      </Mask>
      <G
        mask="url(#eyesRobocop-a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
        fillOpacity=".8"
      >
        <Path d="M76 7h18L82 37H64L76 7ZM52 7h9L49 37h-9L52 7Z" />
      </G>
    </G>
  );
}

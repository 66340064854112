import React from 'react';
import { View, YStack, Text, useTheme, ViewProps } from 'tamagui';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { ThemedMaterialIcon } from '../core/ThemedMaterialIcon';

type Props = {
  icon?: string;
  color?: string;
  value: string;
  selectable?: boolean;
  selected?: boolean;
  onPress?: () => void;
} & ViewProps;

const PX_TO_REM = 16;

const STANDARD_CARDS = [
  'A',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  'J',
  'Q',
  'K',
];

export default function PlayingCard({
  icon,
  color,
  value,
  selectable,
  selected,
  onPress,
  ...viewProps
}: Props) {
  const theme = useTheme();

  return (
    <YStack
      width={3 * PX_TO_REM}
      height={4.5 * PX_TO_REM}
      borderColor={theme.onBackground}
      borderWidth={2}
      borderRadius={5}
      backgroundColor={theme.background}
      userSelect="none"
      onPress={onPress}
      {...(selectable && !selected
        ? {
            outlineColor: '$success50',
            outlineWidth: '$1.5',
            outlineStyle: 'solid',
            hoverStyle: {
              scale: 1.15,
              zIndex: 10,
            },
          }
        : undefined)}
      {...(selectable && selected
        ? {
            outlineColor: '$info50',
            outlineWidth: '$1',
            outlineStyle: 'solid',
            style: {
              scale: 1.1,
              zIndex: 5,
            },
          }
        : undefined)}
      {...viewProps}
    >
      <View marginRight="auto">
        {icon && (
          <ThemedMaterialIcon
            name={icon}
            color={color || 'onBackground'}
            size={16}
            style={{ padding: 2 }}
          />
        )}
      </View>
      <View
        marginTop="auto"
        marginBottom="auto"
        marginLeft="auto"
        marginRight="auto"
      >
        {STANDARD_CARDS.includes(value) && <Text fontSize={18}>{value}</Text>}
        {!STANDARD_CARDS.includes(value) && (
          <ThemedMaterialIcon
            size={24}
            name={value}
            color={color || 'onBackground'}
          />
        )}
      </View>
      <View marginLeft="auto">
        {icon && (
          <ThemedMaterialIcon
            name={icon}
            color={color || 'onBackground'}
            size={16}
            style={{ padding: 2 }}
          />
        )}
      </View>
    </YStack>
  );
}

import React from 'react';
import { FacesBase } from './base';
import { FacesAlienEyes } from './eyes/alien';
import { FacesSmileMouth } from './mouths/smile';

export function FacesAlien() {
  return (
    <FacesBase>
      <FacesAlienEyes />
      <FacesSmileMouth />
    </FacesBase>
  );
}

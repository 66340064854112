import { G, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsNeutralMouth() {
  return (
    <G transform="translate(22 68)">
      <Rect
        x="24"
        y="6"
        width="27"
        height="8"
        rx="4"
        fill="#000"
        fillOpacity=".8"
      />
    </G>
  );
}

import React from 'react';
import { FacesBase } from './base';
import { FacesHeartEyes } from './eyes/hearts';
import { FacesKissMouth } from './mouths/kiss';

export function FacesKissy() {
  return (
    <FacesBase>
      <FacesHeartEyes />
      <FacesKissMouth showHeart={false} />
    </FacesBase>
  );
}

import { StyleSheet, View } from 'react-native';
import { Text, useTheme, YStack } from 'tamagui';
import { UseThemeResult } from '@tamagui/web';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import React from 'react';

export default function Logo() {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.container}>
      <MaterialCommunityIcons
        name="cards"
        size={48}
        color={theme.onBackground?.val}
      />
      <YStack gap="$1">
        <Text fontSize={24}>
          House Rules
        </Text>
        <Text fontSize="$3" color="$primary">
          Playtest
        </Text>
      </YStack>
    </View>
  );
}

const getStyles = (theme: UseThemeResult) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.onBackground?.val,
    },
  });
};

import { G, Path, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsSquareVisorEyes() {
  return (
    <G transform="translate(8 20)">
      <Rect
        x="8"
        y="10"
        width="88"
        height="36"
        rx="4"
        fill="#000"
        fillOpacity=".8"
      />
      <Rect x="28" y="21" width="10" height="17" rx="2" fill="#5EF2B8" />
      <Rect x="66" y="21" width="10" height="17" rx="2" fill="#5EF2B8" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83 10h5L76 46h-5l12-36Z"
        fill="#fff"
        fillOpacity=".4"
      />
    </G>
  );
}

import { G, Circle } from 'react-native-svg';
import React from 'react';

export function BotttsDotEyes() {
  return (
    <G transform="translate(8 20)">
      <G fill="#fff">
        <Circle cx="24" cy="30" r="6" />
        <Circle cx="80" cy="30" r="6" />
      </G>
    </G>
  );
}

import { G, Mask, Rect, Svg } from 'react-native-svg';
import React from 'react';

type Props = {
  children: any | any[];
};

/*
 * "Bottts" by "Pablo Stanley", licensed under "Free for personal and
 * commercial use". / Remix of the original. - Created with dicebear.com
 */
export function BotttsBase({ children }: Props) {
  return (
    <Svg viewBox="0 0 120 120" fill="none">
      <Mask id="viewboxMask">
        <Rect width="120" height="120" rx="0" ry="0" x="0" y="0" fill="#fff" />
      </Mask>
      <G mask="url(#viewboxMask)">{children}</G>
    </Svg>
  );
}

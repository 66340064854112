import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  Button,
  Dialog,
  Adapt,
  Sheet,
  Unspaced,
  useTheme,
  SheetProps,
  ScrollView,
  YStack,
  XStack,
} from 'tamagui';

type Props = {
  title: string;
  description?: string;
  open: boolean;
  children: any | any[];
  buttons: any | any[];
  dismissible?: boolean;
  scrollable?: boolean;
  maxWidth?: 'sm' | 'md' | 'lg';
  onClose?: () => void;
};

const defaultProps: Partial<Props> = {
  dismissible: true,
};

const MAX_WIDTHS = {
  lg: 1000,
  md: 750,
  sm: 500,
};

export function Modal({
  title,
  description,
  open,
  children,
  buttons,
  dismissible,
  scrollable,
  maxWidth,
  onClose,
} = defaultProps) {
  const theme = useTheme();

  const sheetProps: Partial<SheetProps> = dismissible
    ? {
        dismissOnSnapToBottom: !scrollable,
        disableDrag: scrollable,
      }
    : {
        disableDrag: true,
        dismissOnOverlayPress: false,
      };

  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(opening) => {
        if (!opening && dismissible) {
          onClose && onClose();
        }
      }}
    >
      <Adapt when="sm" platform="touch">
        <Sheet
          animation="medium"
          zIndex={200000}
          modal
          snapPointsMode="fit"
          {...sheetProps}
        >
          <Sheet.Frame padding="$4" gap="$4">
            {scrollable && (
              <ScrollView>
                <YStack gap="$4" maxHeight="80vh">
                  <Adapt.Contents />
                </YStack>
              </ScrollView>
            )}
            {!scrollable && <Adapt.Contents />}
          </Sheet.Frame>
          <Sheet.Overlay
            animation="lazy"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
          />
        </Sheet>
      </Adapt>

      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          animation="quick"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />

        <Dialog.Content
          bordered
          elevate
          key="content"
          animateOnly={['transform', 'opacity']}
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          maxWidth={maxWidth && MAX_WIDTHS[maxWidth]}
          gap="$4"
        >
          <Dialog.Title>{title}</Dialog.Title>
          {description && (
            <Dialog.Description>{description}</Dialog.Description>
          )}

          {children}

          {buttons && (
            <XStack alignSelf="flex-end" gap="$2" flexWrap="wrap">
              {buttons}
            </XStack>
          )}

          {dismissible && (
            <Unspaced>
              <Dialog.Close asChild>
                <Button
                  position="absolute"
                  top="$3"
                  right="$3"
                  size="$2"
                  circular
                  bordered={false}
                  theme="alt1"
                  icon={
                    <MaterialCommunityIcons
                      name="close"
                      size={24}
                      color={theme.onBackground?.val}
                    />
                  }
                />
              </Dialog.Close>
            </Unspaced>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  );
}

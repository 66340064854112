import { G, Rect, Path } from 'react-native-svg';
import React from 'react';

export function BotttsLCDMouth() {
  return (
    <G transform="translate(22 68)">
      <Rect
        x="4"
        y="4"
        width="68"
        height="24"
        rx="5"
        fill="#000"
        fillOpacity=".2"
      />
      <Rect
        x="8"
        y="8"
        width="60"
        height="16"
        rx="2"
        fill="#000"
        fillOpacity=".8"
      />
      <Path
        d="M9 17h11l2-4 3 7 4-8 2 9 3-11 3 10 3-3h15l3-4 2 7 3-3h4"
        stroke="#4EFAC9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  );
}

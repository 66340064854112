import { Path, G } from 'react-native-svg';
import React from 'react';

export function FacesHeartEyes() {
  return (
    <G transform="matrix(1.5625 0 0 1.5625 31.25 59.38)">
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M19.16 32.01C11.5 28.4-2.17 22.5-5.14 12.06a10.8 10.8 0 0 1-.36-1.46C-7.32.33 3.58-6.92 13.46.1 19.24-8.3 29.2-7.43 33.28-.83a12.1 12.1 0 0 1 1.6 4.25c2 11.3-9.5 22.27-15.72 28.6Z"
          fill="#CE0F0F"
        />
        <Path
          opacity=".2"
          d="M17.73 28.61C10.96 25.4-.53 20.41-5.13 12.06a10.8 10.8 0 0 1-.37-1.46C-7.32.33 3.58-6.92 13.46.1 19.24-8.3 29.2-7.43 33.28-.83c0 .27.13.55.18.83 1.96 11.33-9.5 22.23-15.73 28.62Z"
          fill="#fff"
        />
        <Path
          opacity=".4"
          d="M-3.74 9.01C-3.18 4.7 5.96-.95 9.74.12 2.38-1.9-2.71 1.58-3.74 9.02Z"
          fill="#fff"
        />
        <Path
          d="M70.07 32.01c7.66-3.62 21.33-9.52 24.29-19.95.15-.48.28-.97.36-1.46C96.54.33 85.65-6.92 75.76.1 69.98-8.3 60.04-7.43 55.95-.83a12.1 12.1 0 0 0-1.6 4.25c-2.03 11.3 9.5 22.27 15.72 28.6Z"
          fill="#CE0F0F"
        />
        <Path
          opacity=".2"
          d="M71.5 28.61c6.77-3.21 18.25-8.2 22.86-16.55.15-.48.28-.97.36-1.46C96.54.33 85.65-6.92 75.76.1 69.98-8.3 60.04-7.43 55.94-.83c0 .27-.13.55-.17.83-2.03 11.33 9.5 22.23 15.72 28.62Z"
          fill="#fff"
        />
        <Path
          opacity=".4"
          d="M92.95 9.01C92.41 4.7 83.25-.95 79.47.12 86.85-1.9 92 1.58 92.95 9.02Z"
          fill="#fff"
        />
      </G>
    </G>
  );
}

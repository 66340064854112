import React from 'react';
import { View, XStack, XStackProps } from 'tamagui';
import { AvatarKey } from '../../avatars';
import { PlayerAvatar } from '../core/PlayerAvatar';
import { KeyedAvatar } from '../../@types/avatars';

type Props<T extends string> = {
  value: string;
  values: KeyedAvatar<T>[];
  color?: string;
  showSelected: boolean;
  onChangeAvatar?: (avatarKey: AvatarKey) => void;
} & XStackProps;

export function AvatarRadioGroup<T extends string>({
  value,
  values,
  color,
  showSelected,
  onChangeAvatar,
  ...props
}: Props<T>) {
  return (
    <XStack flexWrap="wrap" gap="$2" role="radiogroup" {...props}>
      {values.map((avatar, index) => (
        <View
          key={index}
          aria-label={avatar.name}
          aria-selected={value === avatar.key}
          role="radio"
          onPress={() =>
            onChangeAvatar && onChangeAvatar(avatar.key as AvatarKey)
          }
          style={{ cursor: 'pointer' }}
          {...(value === avatar.key && showSelected
            ? {
                borderColor: color,
                borderWidth: '$1',
                borderRadius: 9999,
              }
            : {
                padding: '$1',
              })}
        >
          <PlayerAvatar
            size="sm"
            avatar={avatar.key as AvatarKey}
            color={color}
            margin="$1"
          />
        </View>
      ))}
    </XStack>
  );
}

import { StyleSheet } from 'react-native';
import { Button, Text } from 'tamagui';
import BaseScreen from './BaseScreen';
import React from 'react';
import { useNavigation } from '@react-navigation/native';

export default function NotFoundScreen() {
  const navigation = useNavigation();

  return (
    <BaseScreen header>
      <Text style={styles.text}>
        Unfortunately, the page you were looking for is not available.
      </Text>

      <Button
        onPress={() => {
          navigation.navigate('Home');
        }}
      >
        Go home
      </Button>
    </BaseScreen>
  );
}

const styles = StyleSheet.create({
  text: {
    maxWidth: '75%',
    textAlign: 'center',
  },
});

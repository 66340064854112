import { create } from 'zustand';
import { GameState, Player } from '../utils/game-types';
import { Room } from 'colyseus.js';

type GameStoreState = {
  room: Room<GameState>;
  gameState: GameState;
  players: Player[];
  self?: Player;
};

type GameStoreActions = {
  setRoom: (newValue: Room<GameState>) => void;
  reset: () => void;
};

type GameStore = GameStoreState & GameStoreActions;

const initialState: GameStoreState = {
  room: {} as any,
  gameState: {} as any,
  players: [],
};

export const useGame = create<GameStore>((set, get) => ({
  ...initialState,
  setRoom: (newValue) => {
    set({ room: newValue });

    if (!newValue.onStateChange) {
      return;
    }

    newValue.onStateChange((gameState) => {
      if (gameState.players) {
        const players = Array.from(
          gameState.players,
          ([sessionId, player]) => ({
            ...player,
            sessionId,
          })
        );

        set((state) => ({
          gameState,
          players,
          self: players.find(
            (player) => player.sessionId === state.room.sessionId
          ),
        }));
      } else {
        set({
          gameState,
          players: [],
          self: undefined,
        });
      }
    });
  },
  reset: () => {
    if (get().room) {
      get().room.removeAllListeners();
      get().room.leave(true);
    }
    set(initialState);
  },
}));

import { GestureResponderEvent, StyleSheet } from 'react-native';
import { Button, View } from 'tamagui';
import { AvatarKey } from '../../avatars';
import { PlayerAvatar } from '../core/PlayerAvatar';
import { ThemedMaterialIcon } from '../core/ThemedMaterialIcon';
import React from 'react';

type Props = {
  avatar: AvatarKey;
  avatarColor: string;
  onPress?: (event: GestureResponderEvent) => void;
};

export function EditAvatarButton({ avatar, avatarColor, onPress }: Props) {
  return (
    <View
      style={{ position: 'relative' }}
      hoverStyle={{ cursor: 'pointer' }}
      pressStyle={{ scale: 0.925 }}
      onPress={onPress}
    >
      <PlayerAvatar size="lg" avatar={avatar} color={avatarColor} />
      <Button circular theme="secondary" style={styles.button} size="$2">
        <ThemedMaterialIcon name="pencil" size={18} />
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

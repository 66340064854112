import { Avatar, AvatarSet, KeyedAvatar } from '../@types/avatars';

export const getAvatarList = <T extends string>(set: AvatarSet<T>) => {
  return Object.entries<Avatar>(set).map(
    ([key, avatar]) =>
      ({
        ...avatar,
        key,
      } as KeyedAvatar<T>)
  );
};

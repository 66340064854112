import React from 'react';
import Toast, { ToastProps } from '../components/core/Toast';

export const toastConfig = {
  success: ({ props }: { props: ToastProps }) => (
    <Toast {...props} theme="success" />
  ),
  info: ({ props }: { props: ToastProps }) => <Toast {...props} theme="info" />,
  warning: ({ props }: { props: ToastProps }) => (
    <Toast {...props} theme="warning" />
  ),
};

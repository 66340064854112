import AsyncStorage from '@react-native-async-storage/async-storage';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AvatarKey } from '../avatars';
import { ServerRegion, Theme } from '../utils/constants';
import { generateUniqueUsername } from '../utils/user';
import { pickRandom } from '../utils/arrays';
import { BotttsSet } from '../avatars/bottts';
import { Setters } from './utils';
import { ColyseusProfile } from '../providers/colyseus';

type PreferencesState = {
  serverRegion: ServerRegion;
  theme: Theme;
  username: string;
  avatar: AvatarKey;
  avatarColor: string;
};

type PreferencesStore = PreferencesState & Setters<PreferencesState>;

export const usePrefs = create(
  persist<PreferencesStore>(
    (set) => ({
      serverRegion: 'US',
      theme: 'auto',
      username: generateUniqueUsername(),
      avatar: pickRandom(Object.keys(BotttsSet.avatars) as AvatarKey[]),
      avatarColor: pickRandom(BotttsSet.colorOptions).value,
      setServerRegion: (newValue) => set({ serverRegion: newValue }),
      setTheme: (newValue) => set({ theme: newValue }),
      setAvatar: (newValue) => set({ avatar: newValue }),
      setAvatarColor: (newValue) => set({ avatarColor: newValue }),
      setUsername: (newValue) => set({ username: newValue }),
    }),
    {
      name: 'prefs-storage',
      getStorage: () => AsyncStorage,
    }
  )
);

export const useProfile = (): ColyseusProfile => {
  return usePrefs((state) => ({
    username: state.username,
    avatar: state.avatar,
    avatarColor: state.avatarColor,
  }));
};

export const useProfileSetters = (): Setters<ColyseusProfile> => {
  return usePrefs((state) => ({
    setUsername: state.setUsername,
    setAvatar: state.setAvatar,
    setAvatarColor: state.setAvatarColor,
  }));
};

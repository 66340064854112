import { Svg } from 'react-native-svg';
import React from 'react';

type Props = {
  children: any | any[];
};

export function FacesBase({ children }: Props) {
  return (
    <Svg viewBox="0 0 200 200" fill="none">
      {children}
    </Svg>
  );
}

import { Button, styled } from 'tamagui';

export const StyledButton = styled(Button, {
  variants: {
    disabled: {
      true: {
        opacity: 0.25,
      },
    },
  } as const,
});

import { XStack, ScrollView, ViewProps, View, YStack } from 'tamagui';
import { StyleSheet } from 'react-native';
import { LinearGradient } from 'tamagui/linear-gradient';
import React from 'react';

type Props = ViewProps & {
  horizontal?: boolean;
};

export default function GradientScrollView({
  horizontal,
  gap,
  maxWidth,
  maxHeight,
  children,
  ...props
}: Props) {
  return (
    <View {...props}>
      {horizontal && (
        <>
          <LinearGradient
            width="$3"
            height="100%"
            colors={['$background', 'transparent']}
            start={[0, 1]}
            end={[0, 1]}
            style={styles.leftFade}
            zIndex={10}
          />
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            maxWidth={maxWidth}
            zIndex={5}
          >
            <XStack gap={gap} marginLeft="$6" marginRight="$6">
              {children}
            </XStack>
          </ScrollView>
          <LinearGradient
            width="$3"
            height="100%"
            colors={['transparent', '$background']}
            start={[0, 1]}
            end={[0, 1]}
            style={styles.rightFade}
            zIndex={10}
          />
        </>
      )}
      {!horizontal && (
        <>
          <LinearGradient
            width="100%"
            height="$3"
            colors={['$background', 'transparent']}
            start={[0, 0]}
            end={[1, 1]}
            style={styles.topFade}
            zIndex={10}
          />
          <ScrollView
            showsVerticalScrollIndicator={false}
            maxHeight={maxHeight}
            zIndex={5}
          >
            <YStack gap={gap} marginTop="$6" marginBottom="$6">
              {children}
            </YStack>
          </ScrollView>
          <LinearGradient
            width="100%"
            height="$3"
            colors={['transparent', '$background']}
            start={[0, 0]}
            end={[1, 1]}
            style={styles.bottomFade}
            zIndex={10}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  leftFade: {
    top: 0,
    left: 0,
    position: 'absolute',
  },
  rightFade: {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  topFade: {
    top: 0,
    left: 0,
    position: 'absolute',
  },
  bottomFade: {
    bottom: 0,
    left: 0,
    position: 'absolute',
  },
});

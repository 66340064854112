import { BotttsBase } from './base';
import { BotttsDotEyes } from './eyes/dots';
import { BotttsFiveVentsMouth } from './mouths/five-vents';
import React from 'react';

export function BotttsR3pwn() {
  return (
    <BotttsBase>
      <BotttsDotEyes />
      <BotttsFiveVentsMouth />
    </BotttsBase>
  );
}

import { useTheme } from 'tamagui';
import { IconProps } from 'react-native-vector-icons/Icon';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { UseThemeResult } from '@tamagui/web';
import React from 'react';

type ThemedIconProps = IconProps & {
  color?: string;
};

export function ThemedMaterialIcon({ color, ...iconProps }: ThemedIconProps) {
  const theme = useTheme();

  let iconColor = color;

  if (theme[color as keyof UseThemeResult]?.val) {
    iconColor = theme[color as keyof UseThemeResult].val;
  }

  return <MaterialCommunityIcon {...iconProps} color={iconColor} />;
}

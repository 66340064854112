import { BotttsBase } from './base';
import { BotttsSquareVisorEyes } from './eyes/square-visor';
import { BotttsOpenSmileMouth } from './mouths/open-smile';
import React from 'react';

export function BotttsDaisy() {
  return (
    <BotttsBase>
      <BotttsSquareVisorEyes />
      <BotttsOpenSmileMouth />
    </BotttsBase>
  );
}

import { Card, ThemeName, XStack, Text, useTheme, ViewProps } from 'tamagui';
import { StyleSheet } from 'react-native';
import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

export type DecorativeLabelProps = Omit<ViewProps, 'theme'> & {
  text: string;
  icon?: string;
  theme?: 'success' | 'info' | 'warning';
};

export default function DecorativeLabel({
  text,
  icon,
  theme,
  ...viewProps
}: DecorativeLabelProps) {
  const cardTheme = useTheme({
    name: theme as ThemeName,
    componentName: 'Card',
  });

  return (
    <Card
      size="$1"
      bordered
      theme={theme as ThemeName}
      padding="$1"
      paddingLeft="$2"
      paddingRight="$2"
      {...viewProps}
    >
      <XStack>
        {icon && (
          <MaterialCommunityIcons
            name={icon}
            size={16}
            color={cardTheme.color?.val}
            style={styles.icon}
          />
        )}
        <Text>{text}</Text>
      </XStack>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 4,
  },
  icon: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

import React from 'react';
import { Circle, View, XStack, XStackProps } from 'tamagui';

type ColorOption = {
  value: string;
  name: string;
};

type Props = {
  value: string;
  values: ColorOption[];
  showSelected: boolean;
  onChangeColor?: (color: string) => void;
} & XStackProps;

export function ColorRadioGroup({
  value,
  values,
  showSelected,
  onChangeColor,
  ...props
}: Props) {
  return (
    <XStack flexWrap="wrap" gap="$2" role="radiogroup" {...props}>
      {values.map((colorOption) => (
        <View
          key={colorOption.value}
          aria-label={`color option ${colorOption.name}`}
          aria-selected={value === colorOption.value}
          role="radio"
          style={{ cursor: 'pointer' }}
          {...(value === colorOption.value && showSelected
            ? {
                borderColor: colorOption.value,
                borderWidth: '$1',
                borderRadius: 9999,
              }
            : {
                padding: '$1',
              })}
          onPress={() => {
            onChangeColor && onChangeColor(colorOption.value);
          }}
        >
          <Circle size="$2" backgroundColor={colorOption.value} margin="$1" />
        </View>
      ))}
    </XStack>
  );
}

import React, { useState } from 'react';
import { Text, Input, Spinner } from 'tamagui';
import { Modal } from '../core/Modal';
import { StyledButton } from '../core/StyledButton';
import { useNavigation } from '@react-navigation/native';
import colyseus from '../../providers/colyseus';
import Toast from 'react-native-toast-message';

type Props = {
  open: boolean;
  onClose: () => void;
};

const MAX_CODE_LENGTH = 5;

export function JoinGameModal({ open, onClose }: Props) {
  const [roomCode, setRoomCode] = useState('');
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation();

  const roomCodeInput = (inputText: string) => {
    const sanitizedInputText = inputText
      .slice(0, 5)
      .replaceAll('1', 'I')
      .replaceAll('0', 'O')
      .toUpperCase();
    setRoomCode(sanitizedInputText.trim());
  };

  const joinGame = () => {
    if (roomCode.length !== MAX_CODE_LENGTH || loading) {
      return;
    }

    setLoading(true);

    colyseus
      .getLobbyByCode(roomCode)
      .then((room) => {
        navigation.navigate('Lobby', {
          lobbyId: room.roomId,
        });
        onModalClose();
      })
      .catch(() => {
        Toast.show({
          type: 'warning',
          props: {
            icon: 'alert',
            title: 'Unable to join room',
            message: 'Please double-check your room code and try again',
          },
          swipeable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onModalClose = () => {
    setLoading(false);
    setRoomCode('');

    onClose();
  };

  return (
    <Modal
      open={open}
      title="Join a game"
      description="Join an existing game using a 5-character game code provided by the host."
      dismissible
      maxWidth="sm"
      buttons={
        <>
          <StyledButton onPress={onModalClose} theme="secondary">
            Cancel
          </StyledButton>
          <StyledButton
            disabled={roomCode.length !== MAX_CODE_LENGTH}
            onPress={joinGame}
            icon={loading ? <Spinner /> : undefined}
          >
            Join game
          </StyledButton>
        </>
      }
      onClose={onModalClose}
    >
      <Input
        size="$6"
        borderWidth={2}
        placeholder="ex. ABC12"
        value={roomCode}
        onChangeText={roomCodeInput}
        onSubmitEditing={joinGame}
      />
      <Text>
        <Text fontWeight="bold">Note</Text>: This code will use a capital "i"
        instead of the number one, and a capital "o" instead of the number zero.
      </Text>
    </Modal>
  );
}

import { Path, G } from 'react-native-svg';
import React from 'react';

export function FacesAlienEyes() {
  return (
    <G transform="matrix(1.5625 0 0 1.5625 31.25 59.38)">
      <Path
        d="M 73.63 0 a 13.01 13.01 180 0 1 13 13 v 0.66 C 86.62 24.1 75.07 30.87 67.68 29.9 c -6.56 -0.84 -7.07 -5.02 -7.07 -11.24 V 13 a 13 13 180 0 1 13 -13 Z"
        fill="#000"
      />
      <Path
        d="M70.2 9.67a2.29 2.29 0 1 0 0-4.58 2.29 2.29 0 0 0 0 4.58Z"
        fill="#fff"
      />
      <Path
        opacity=".1"
        d="M74.44 18.48a5.32 5.32 0 1 0 0-10.64 5.32 5.32 0 0 0 0 10.64Z"
        fill="#fff"
      />
      <Path
        d="M 15 0 a 13 13 180 0 0 -13 13 v 0.66 C 2 24.1 13.55 30.87 20.93 29.9 c 6.54 -0.84 7.07 -5.02 7.07 -11.24 V 13 a 13.01 13.01 180 0 0 -13 -13 Z"
        fill="#000"
      />
      <Path
        d="M9.22 9.67a2.29 2.29 0 1 0 0-4.58 2.29 2.29 0 0 0 0 4.58Z"
        fill="#fff"
      />
      <Path
        opacity=".1"
        d="M13.46 18.48a5.32 5.32 0 1 0 0-10.64 5.32 5.32 0 0 0 0 10.64Z"
        fill="#fff"
      />
    </G>
  );
}

import { BotttsBase } from './base';
import { BotttsRoundVisorOpenEyes } from './eyes/round-visor-open';
import { BotttsOpenSmileMouth } from './mouths/open-smile';
import React from 'react';

export function BotttsKitty() {
  return (
    <BotttsBase>
      <BotttsRoundVisorOpenEyes />
      <BotttsOpenSmileMouth />
    </BotttsBase>
  );
}

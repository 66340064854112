import { StyleSheet, View } from 'react-native';
import { Unspaced, Text, useTheme, XStack, YStack } from 'tamagui';
import { UseThemeResult } from '@tamagui/web';

import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

type Props = {
  header?: boolean;
  draggable?: boolean;
  children: any | any[];
};

export default function BaseScreen({ header, draggable, children }: Props) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const pageContent = (
    <>
      {header && (
        <Unspaced>
          <XStack gap="$2" padding="$3">
            <MaterialCommunityIcons
              name="cards"
              size={32}
              color={theme.onBackground?.val}
              style={{ alignContent: 'center' }}
            />
            <YStack gap="$1">
              <Text fontSize="$6">
                House Rules
              </Text>
              <Text fontSize="$3" color="$primary">
                Playtest
              </Text>
            </YStack>
          </XStack>
        </Unspaced>
      )}
      <View style={styles.pageContainer}>{children}</View>
    </>
  );

  return (
    <SafeAreaView style={styles.parentContainer}>
      {draggable && (
        <GestureHandlerRootView style={{ height: '100%' }}>
          {pageContent}
        </GestureHandlerRootView>
      )}
      {!draggable && pageContent}
    </SafeAreaView>
  );
}

const getStyles = (theme: UseThemeResult) => {
  return StyleSheet.create({
    parentContainer: {
      flex: 1,
      backgroundColor: theme.background?.val,
    },
    pageContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
    },
  });
};

import { BotttsBase } from './base';
import { BotttsRobocopVisorEyes } from './eyes/robocop-visor';
import { BotttsNeutralMouth } from './mouths/neutral';
import React from 'react';

export function BotttsFrankie() {
  return (
    <BotttsBase>
      <BotttsRobocopVisorEyes />
      <BotttsNeutralMouth />
    </BotttsBase>
  );
}

import { InputProps, Input } from 'tamagui';
import React, { useEffect, useState } from 'react';

export type NumericInputProps = Omit<InputProps, 'value' | 'onChangeText'> & {
  value: number;
  onChangeValue: (value: number) => void;
};

export default function NumericInput({
  value,
  onChangeValue,
  ...props
}: NumericInputProps) {
  const [stringValue, setStringValue] = useState(String(value));

  useEffect(() => {
    if (String(value) !== stringValue) {
      setStringValue(String(value));
    }
  }, [value]);

  useEffect(() => {
    onChangeValue && onChangeValue(Number(stringValue));
  }, [stringValue]);

  const stripNonNumericChars = (text: string) => {
    setStringValue(text.replaceAll(/[^0-9+]/g, ''));
  };

  return (
    <Input
      value={stringValue}
      onChangeText={stripNonNumericChars}
      inputMode="numeric"
      {...props}
    />
  );
}

import { config } from '@tamagui/config/v2-native';

import { ColorPalette, generatePalette } from './helpers';

type GlobalColorPalettes = {
  [key: string]: ColorPalette;
};

export const appColorPalettes: GlobalColorPalettes = {
  primary: {
    10: '#22005d',
    20: '#381e72',
    30: '#4f378a',
    40: '#6750a4',
    50: '#8069bf',
    60: '#9a83db',
    70: '#b69df8',
    80: '#cfbcff',
    90: '#e9ddff',
    95: '#f6eeff',
  },
  secondary: {
    10: '#3e001d',
    20: '#5e1132',
    30: '#7b2949',
    40: '#984061',
    50: '#b75879',
    60: '#d57193',
    70: '#f48bae',
    80: '#ffb1c8',
    90: '#ffd9e2',
    95: '#ffecf0',
  },
  tertiary: {
    10: '#341100',
    20: '#552000',
    30: '#793100',
    40: '#984716',
    50: '#b75f2d',
    60: '#d77743',
    70: '#f7915b',
    80: '#ffb692',
    90: '#ffdbcb',
    95: '#ffede6',
  },
};

export const supplementaryColorPalettes: GlobalColorPalettes = {
  success: {
    10: '#00210d',
    20: '#00391a',
    30: '#005229',
    40: '#006d38',
    50: '#2b874f',
    60: '#48a266',
    70: '#64bd7f',
    80: '#7fd998',
    90: '#9bf6b3',
    95: '#c3ffce',
  },
  info: {
    10: '#221b00',
    20: '#3a3000',
    30: '#544600',
    40: '#6f5d00',
    50: '#8c7600',
    60: '#a98f00',
    70: '#c8a900',
    80: '#e7c400',
    90: '#ffe167',
    95: '#fff0c1',
  },
  warning: {
    10: '#410002',
    20: '#690005',
    30: '#93000a',
    40: '#ba1a1a',
    50: '#de3730',
    60: '#ff5449',
    70: '#ff897d',
    80: '#ffb4ab',
    90: '#ffdad6',
    95: '#ffedea',
  },
};

export const appColors = {
  ...config.tokens.color,

  backgroundDark: '#1C1B1F',
  onBackgroundDark: '#FFFFFF',
  backgroundLight: '#FFFFFF',
  onBackgroundLight: '#1C1B1F',

  ...generatePalette('primary', appColorPalettes.primary),
  ...generatePalette('secondary', appColorPalettes.secondary),
  ...generatePalette('tertiary', appColorPalettes.tertiary),

  ...generatePalette('success', supplementaryColorPalettes.success),
  ...generatePalette('info', supplementaryColorPalettes.info),
  ...generatePalette('warning', supplementaryColorPalettes.warning),
};

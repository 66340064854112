import { FacesAvatar, FacesSet } from './faces';
import { BotttsAvatar, BotttsSet } from './bottts';

export type AvatarKey = BotttsAvatar | FacesAvatar;

export const AvatarSets = {
  bottts: BotttsSet,
  faces: FacesSet,
};

export const AvatarList = {
  ...BotttsSet.avatars,
  ...FacesSet.avatars,
};

export const getAvatarSetFor = (selectedAvatar: string) => {
  return selectedAvatar.split('-')[0];
};

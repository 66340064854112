import { G, Path } from 'react-native-svg';
import React from 'react';

export function BotttsSmileMouth() {
  return (
    <G transform="translate(22 68)">
      <Path
        d="M27.05 8.44a2 2 0 1 1 3.9-.88C31.72 10.96 34.4 13 38 13c3.6 0 6.28-2.04 7.05-5.44a2 2 0 1 1 3.9.88C47.75 13.7 43.43 17 38 17s-9.76-3.3-10.95-8.56Z"
        fill="#000"
        fillOpacity=".6"
      />
    </G>
  );
}

import { Path, G } from 'react-native-svg';
import React from 'react';

type Props = {
  showHeart: boolean;
};

export function FacesKissMouth({ showHeart }: Props) {
  return (
    <G transform="matrix(1.5625 0 0 1.5625 37.5 110.94)">
      <Path
        d="M40.3 36c-.35 0-.68-.15-.92-.4a1.43 1.43 0 0 1-.38-.98c0-.37.14-.72.38-.98s.57-.4.91-.4c3.77 0 5.99-1.38 6.1-3.75.1-2.73-2.52-6.05-6.22-6.43a1.26 1.26 0 0 1-.84-.49 1.5 1.5 0 0 1 .08-1.88c.23-.25.52-.4.84-.44 3.66-.25 6.11-3.25 6.04-5.82 0-2.3-2.3-3.67-5.96-3.67-.27-.1-.5-.28-.67-.53a1.53 1.53 0 0 1 0-1.71c.16-.25.4-.43.67-.53 6.17 0 8.45 3.28 8.55 6.35a8.98 8.98 0 0 1-1.23 4.26 8.22 8.22 0 0 1-3.06 3.02 8.91 8.91 0 0 1 3.18 3.3A9.7 9.7 0 0 1 49 29.48C48.84 32.7 46.48 36 40.3 36Z"
        fill="#000"
      />
      {showHeart && (
        <G fillRule="evenodd" clipRule="evenodd">
          <Path
            d="M107.8 10.27c-.09.5-.2.98-.36 1.46-2.88 10.4-16.3 16.3-23.8 19.9-6.09-6.32-17.4-17.25-15.4-28.6.25-1.51.78-2.96 1.58-4.25 3.97-6.6 13.73-7.44 19.41.96 9.68-6.95 20.37.34 18.57 10.53Z"
            fill="#CE0F0F"
          />
          <Path
            opacity=".2"
            d="M107.8 10.27c-.09.5-.2.98-.36 1.46-4.52 8.33-15.82 13.3-22.4 16.5C78.87 21.98 67.61 11.1 69.6-.32c0-.29.12-.57.2-.85 3.96-6.58 13.73-7.43 19.4.97 9.7-7.01 20.39.28 18.6 10.47Z"
            fill="#fff"
          />
        </G>
      )}
    </G>
  );
}

import { Button, ToggleGroup, Text, XStack } from 'tamagui';

import React, { useEffect, useState } from 'react';
import { Modal } from '../core/Modal';
import { ThemedMaterialIcon } from '../core/ThemedMaterialIcon';

const CARD_SUITES = [
  {
    name: 'heart',
    icon: 'cards-heart',
    color: 'red',
  },
  {
    name: 'diamond',
    icon: 'cards-diamond',
    color: 'red',
  },
  {
    name: 'club',
    icon: 'cards-club',
    color: 'onBackground',
  },
  {
    name: 'spade',
    icon: 'cards-spade',
    color: 'onBackground',
  },
];

type Props = {
  open: boolean;
  currentSuite: string;
  onCancel: () => void;
  onChangeSuite: (suite: string) => void;
};

export default function ChangeSuiteModal({
  open,
  currentSuite,
  onCancel,
  onChangeSuite,
}: Props) {
  const [selectedSuite, setSelectedSuite] = useState('');

  useEffect(() => {
    setSelectedSuite(currentSuite);
  }, [currentSuite]);

  const currentSuiteObj = CARD_SUITES.find(
    (suite) => suite.name === currentSuite
  );

  return (
    <Modal
      title="Select a suite"
      description="Tap the suite you wish to select (you can choose to keep it the same)"
      dismissible={false}
      open={open}
      buttons={
        <>
          <Button theme="secondary" onPress={() => onCancel()}>
            Peek at my hand
          </Button>
          <Button onPress={() => onChangeSuite(selectedSuite)}>
            Select suite
          </Button>
        </>
      }
    >
      <XStack gap="$2" style={{ placeItems: 'center' }}>
        <Text>Current Suite:</Text>
        <ThemedMaterialIcon
          name={currentSuiteObj?.icon || 'plus'}
          color={currentSuiteObj?.color}
          size={24}
        />
      </XStack>
      <ToggleGroup
        orientation="horizontal"
        id="new-suite"
        type="single"
        size="$5"
        disableDeactivation
        marginLeft="auto"
        marginRight="auto"
        value={selectedSuite}
        onValueChange={setSelectedSuite}
      >
        {CARD_SUITES.map((suite) => (
          <ToggleGroup.Item value={suite.name} key={suite.name}>
            <ThemedMaterialIcon name={suite.icon} color={suite.color} />
          </ToggleGroup.Item>
        ))}
      </ToggleGroup>
    </Modal>
  );
}

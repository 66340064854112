import React, { useEffect, useState } from 'react';
import { View, Text, XStack, YStack, ZStack, Button } from 'tamagui';
import { HandCard } from '../../utils/game-types';
import PlayingCard from '../game/PlayingCard';
import { useGame } from '../../store/game';
import { ThemedMaterialIcon } from '../core/ThemedMaterialIcon';
import { StyledButton } from '../core/StyledButton';
import DecorativeLabel from '../core/DecorativeLabel';
import ChangeSuiteModal from '../game/ChangeSuiteModal';
import Toast from 'react-native-toast-message';
import { useNavigation } from '@react-navigation/native';
import { PlayerCarousel } from '../game/PlayerCarousel';

const CARD_SUITES = [
  {
    name: 'heart',
    icon: 'cards-heart',
    color: 'red',
  },
  {
    name: 'diamond',
    icon: 'cards-diamond',
    color: 'red',
  },
  {
    name: 'club',
    icon: 'cards-club',
    color: 'onBackground',
  },
  {
    name: 'spade',
    icon: 'cards-spade',
    color: 'onBackground',
  },
  {
    name: 'special',
  },
];

const mapCard = (card: HandCard) => {
  const matchingSuite = CARD_SUITES.find((suite) => suite.name === card.suite);

  let mappedCard = {
    icon: matchingSuite?.icon,
    color: matchingSuite?.color,
    suite: card.suite,
    value: card.value,
    selectable: card.selectable,
  };

  if (mappedCard.value === '*R') {
    mappedCard.value = 'account-cowboy-hat';
    mappedCard.color = 'red';
  } else if (mappedCard.value === '*B') {
    mappedCard.value = 'account-cowboy-hat-outline';
    mappedCard.color = 'onBackground';
  }

  return mappedCard;
};

export default function StartedLobby() {
  const navigation = useNavigation();

  const { gameState, self, room, players, resetState } = useGame((state) => ({
    gameState: state.gameState,
    self: state.self,
    room: state.room,
    players: state.players,
    resetState: state.reset,
  }));
  const { topCard, hands } = gameState;

  const isMyTurn = gameState.currentPlayer === self?.sessionId;
  const currentHand = hands?.get(self?.sessionId || '');

  const mappedTopCard = mapCard({
    suite: gameState.currentSuite,
    value: topCard?.value,
  });

  const [selectedCard, setSelectedCard] = useState(-1);
  const [changeSuiteModalOpen, setChangeSuiteModalOpen] = useState(false);
  const [isChangingSuite, setIsChangingSuite] = useState(false);

  useEffect(() => {
    if (!room.onMessage) {
      return;
    }

    room.onMessage('querySuite', () => {
      setIsChangingSuite(true);
      setChangeSuiteModalOpen(true);
    });

    room.onMessage('turnSkipped', (enemy) => {
      Toast.show({
        type: 'info',
        props: {
          icon: 'debug-step-over',
          title: `${enemy} skipped your turn!`,
        },
        swipeable: true,
      });
    });

    room.onMessage('yourTurn', () => {
      Toast.show({
        type: 'success',
        props: {
          icon: 'timer-sand-complete',
          title: "It's your turn!",
        },
        swipeable: true,
      });
    });

    room.onMessage('gameWin', (winner) => {
      Toast.show({
        type: 'success',
        props: {
          icon: 'party-popper',
          title: `${winner} wins!`,
        },
        swipeable: true,
      });
    });
  }, [room]);

  const playCard = () => {
    if (!isMyTurn || selectedCard === -1) {
      return;
    }

    room.send('playCard', selectedCard);
    setSelectedCard(-1);
  };

  const drawCard = () => {
    if (!isMyTurn) {
      return;
    }

    room.send('drawCard', selectedCard);
    setSelectedCard(-1);
    Toast.hide();
  };

  const goHome = () => {
    navigation.navigate('Home');
    resetState();
  };

  const canDie =
    isMyTurn &&
    (currentHand?.size || 0) + (gameState.currentDamage || 1) >
      gameState.rules.maxCards;

  return (
    <>
      <YStack height="100%" width="100%" maxWidth="500px">
        <View width="100%" aspectRatio="1 / 1" flexShrink={1}>
          <YStack margin="auto" gap="$6">
            {self && <PlayerCarousel maxWidth="$15" />}
            <YStack gap="$1">
              <PlayingCard
                icon={mappedTopCard.icon}
                color={mappedTopCard.color}
                value={mappedTopCard.value}
                marginLeft="auto"
                marginRight="auto"
              />
              {gameState.currentDamage > 0 ? (
                <DecorativeLabel
                  theme="warning"
                  text={`+${gameState.currentDamage}`}
                  marginLeft="auto"
                  marginRight="auto"
                />
              ) : undefined}
              {players.length > 2 && (
                <View marginLeft="auto" marginRight="auto">
                  <ThemedMaterialIcon
                    name={
                      gameState.currentDirectionClockwise
                        ? 'arrow-right'
                        : 'arrow-left'
                    }
                    color="onBackground"
                    size={24}
                    style={{ padding: 4 }}
                  />
                </View>
              )}
            </YStack>
          </YStack>
        </View>
        <YStack
          marginTop="auto"
          marginLeft="auto"
          marginRight="auto"
          marginBottom="$6"
          gap="$6"
        >
          {!gameState.gameEnded && (
            <>
              <XStack gap="$2" marginLeft="auto" marginRight="auto">
                {isChangingSuite && (
                  <StyledButton
                    size="$5"
                    icon={<ThemedMaterialIcon name="cards-playing-outline" />}
                    onPress={() => {
                      setChangeSuiteModalOpen(true);
                    }}
                  >
                    Pick a suite
                  </StyledButton>
                )}
                {!isChangingSuite && (
                  <>
                    {canDie && (
                      <StyledButton
                        circular
                        theme="secondary"
                        size="$5"
                        onPress={drawCard}
                      >
                        <ThemedMaterialIcon name="skull-outline" size={32} />
                      </StyledButton>
                    )}
                    {!canDie && (
                      <StyledButton
                        circular
                        theme="secondary"
                        size="$5"
                        disabled={!isMyTurn}
                        onPress={drawCard}
                      >
                        <ThemedMaterialIcon
                          name="import"
                          size={32}
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      </StyledButton>
                    )}
                    <StyledButton
                      circular
                      size="$5"
                      disabled={!isMyTurn || selectedCard === -1}
                      onPress={playCard}
                    >
                      <ThemedMaterialIcon name="check" size={32} />
                    </StyledButton>
                  </>
                )}
              </XStack>
              {canDie && (
                <Text color="$warning" marginLeft="auto" marginRight="auto">
                  Drawing cards will put you over the card limit
                </Text>
              )}
              <XStack
                gap="$2"
                marginLeft="auto"
                marginRight="auto"
                paddingLeft="$1.5"
                paddingRight="$1.5"
                flexWrap="wrap"
              >
                {currentHand?.cards
                  .map((card) => mapCard(card))
                  .map((card, index) => (
                    <PlayingCard
                      key={`card-${card.suite}-${card.value}-${index}`}
                      icon={card.icon}
                      color={card.color}
                      value={card.value}
                      selectable={isMyTurn && card.selectable}
                      selected={
                        isMyTurn && card.selectable && selectedCard === index
                      }
                      onPress={() => {
                        if (!card.selectable) {
                          return;
                        }
                        setSelectedCard(index);
                        Toast.hide();
                      }}
                    />
                  ))}
              </XStack>
            </>
          )}
          {gameState.gameEnded && <Button onPress={goHome}>Go home</Button>}
        </YStack>
      </YStack>
      <ChangeSuiteModal
        open={changeSuiteModalOpen}
        currentSuite={gameState.currentSuite}
        onCancel={() => {
          setChangeSuiteModalOpen(false);
        }}
        onChangeSuite={(suite) => {
          room.send('changeSuite', suite);
          setChangeSuiteModalOpen(false);
          setIsChangingSuite(false);
        }}
      />
    </>
  );
}

import { config } from '@tamagui/config/v2-native';

import { createTamagui } from 'tamagui';
import { appTokens } from './src/theme/tokens';
import { appThemes } from './src/theme/themes';

const tamaguiConfig = createTamagui({
  ...config,
  tokens: appTokens,
  themes: appThemes,
});

type Conf = typeof tamaguiConfig;

declare module 'tamagui' {
  interface TamaguiCustomConfig extends Conf {}
}

export default tamaguiConfig;

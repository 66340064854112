import { G, Rect, Path, Mask } from 'react-native-svg';
import React from 'react';

export function BotttsOpenSmileMouth() {
  return (
    <G transform="translate(22 68)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.22C18 21.78 24.47 28 38 28c13.52 0 20-6.34 20-17.78C58 9.5 57.17 8 55 8H21c-2.05 0-3 1.38-3 2.22Z"
        fill="#000"
        fillOpacity=".8"
      />
      <Mask
        id="mouthSmile02-a"
        maskUnits="userSpaceOnUse"
        x="18"
        y="8"
        width="40"
        height="20"
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10.22C18 21.78 24.47 28 38 28c13.52 0 20-6.34 20-17.78C58 9.5 57.17 8 55 8H21c-2.05 0-3 1.38-3 2.22Z"
          fill="#fff"
        />
      </Mask>
      <G mask="url(#mouthSmile02-a)">
        <Rect x="30" y="2" width="16" height="14" rx="2" fill="#fff" />
      </G>
    </G>
  );
}

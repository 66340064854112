import { Path, G } from 'react-native-svg';
import React from 'react';

export function FacesFrownMouth() {
  return (
    <G transform="matrix(1.5625 0 0 1.5625 37.5 110.94)">
      <Path
        d="M40.54 12h-.75c-9.7.22-20.8 5.3-23.7 16.15a1.36 1.36 0 0 0 .44 1.55 1.41 1.41 0 0 0 2.26-.86c2.55-9.46 12.42-13.89 21.06-14.08 8.24-.16 19.04 3.84 22.46 14.57a1.47 1.47 0 0 0 1.65.55A1.44 1.44 0 0 0 65 28.5C61.85 18.69 52.3 12 40.54 12Z"
        fill="#000"
      />
    </G>
  );
}

import { config } from '@tamagui/config/v2-native';

import { createTokens } from 'tamagui';
import { appColors } from './tokens/colors';

export const appTokens = createTokens({
  ...config.tokens,
  color: appColors,
});

export const useTokens = () => appTokens;

import { pickRandom } from './arrays';

const adjectives = [
  'amazing',
  'authentic',
  'auburn',
  'awful',
  'bashful',
  'blue',
  'crimson',
  'clear',
  'delightful',
  'ecstatic',
  'entrancing',
  'fantastic',
  'green',
  'gregarious',
  'happy',
  'hilarious',
  'indigo',
  'iridescent',
  'joking',
  'loopy',
  'lovely',
  'orange',
  'outstanding',
  'preferred',
  'premier',
  'purple',
  'qualifying',
  'ravishing',
  'red',
  'riveting',
  'short',
  'stunning',
  'tall',
  'terrible',
  'terrific',
  'untrustworthy',
  'violet',
  'yellow',
];

const nouns = [
  'animal',
  'builder',
  'cat',
  'chef',
  'client',
  'developer',
  'dinosaur',
  'doctor',
  'engineer',
  'gamer',
  'giraffe',
  'handiman',
  'jester',
  'lawyer',
  'mechanic',
  'ninja',
  'pilot',
  'salamander',
  'scientist',
  'snake',
  'teacher',
  'tiger',
];

/** returns the input string with the first letter capitalized */
const capitalize = (input: string) => {
  return `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
};

const generateBaseUsername = () => {
  const randomAdjective = capitalize(pickRandom(adjectives));
  const randomNoun = capitalize(pickRandom(nouns));
  return `${randomAdjective}${randomNoun}`;
};

const randomNumber = (start: number, end: number) => {
  return (Math.random() * end + start) >> 0;
};

export const generateUniqueUsername = () => {
  return `${generateBaseUsername()}${randomNumber(1, 999)}`;
};

export const generateUniqueAvatarSeed = () => {
  return `houserules-${generateBaseUsername()}${randomNumber(1, 9999)}`;
};

import {
  AvatarColorOption,
  AvatarSet,
  AvatarSetDetails,
} from '../../@types/avatars';
import { getAvatarList } from '../helpers';
import { BotttsDaisy } from './daisy';
import { BotttsFluffy } from './fluffy';
import { BotttsFrankie } from './frankie';
import { BotttsHarley } from './harley';
import { BotttsKitty } from './kitty';
import { BotttsR3pwn } from './r3pwn';
import { BotttsRalphie } from './ralphie';

export type BotttsAvatar =
  | 'bottts-daisy'
  | 'bottts-fluffy'
  | 'bottts-frankie'
  | 'bottts-harley'
  | 'bottts-kitty'
  | 'bottts-r3pwn'
  | 'bottts-ralphie';

const colorOptions: AvatarColorOption[] = [
  {
    value: '#d93636',
    name: 'Red',
  },
  {
    value: '#db8b1a',
    name: 'Orange',
  },
  {
    value: '#f2ea0a',
    name: 'Yellow',
  },
  {
    value: '#35ed0c',
    name: 'Green',
  },
  {
    value: '#0366fc',
    name: 'Blue',
  },
  {
    value: '#8309ed',
    name: 'Purple',
  },
];

const avatars: AvatarSet<BotttsAvatar> = {
  'bottts-daisy': {
    name: 'Daisy',
    render: BotttsDaisy,
  },
  'bottts-fluffy': {
    name: 'Fluffy',
    render: BotttsFluffy,
  },
  'bottts-frankie': {
    name: 'Frankie',
    render: BotttsFrankie,
  },
  'bottts-harley': {
    name: 'Harley',
    render: BotttsHarley,
  },
  'bottts-kitty': {
    name: 'Kitty',
    render: BotttsKitty,
  },
  'bottts-r3pwn': {
    name: 'r3pwn',
    render: BotttsR3pwn,
  },
  'bottts-ralphie': {
    name: 'Ralphie',
    render: BotttsRalphie,
  },
};

export const BotttsSet: AvatarSetDetails<BotttsAvatar> = {
  name: 'Bottts',
  avatars,
  avatarList: getAvatarList(avatars),
  colorOptions,
};

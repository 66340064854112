import { G, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsFourVentsMouth() {
  return (
    <G transform="translate(22 68)">
      <G fill="#000" fillOpacity=".6">
        <Rect x="28" y="10" width="6" height="14" rx="2" />
        <Rect x="14" y="10" width="6" height="14" rx="2" />
        <Rect x="42" y="10" width="6" height="14" rx="2" />
        <Rect x="56" y="10" width="6" height="14" rx="2" />
      </G>
    </G>
  );
}

import {
  Circle,
  Paragraph,
  Separator,
  SizableText,
  Text,
  View,
  XStack,
  YStack,
} from 'tamagui';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  DEFAULT_FALLBACK_AVATAR,
  DEFAULT_FALLBACK_AVATAR_COLOR,
} from '../../utils/avatar';
import { useTokens } from '../../theme/tokens';
import { PlayerAvatar } from '../core/PlayerAvatar';
import { StyledButton } from '../core/StyledButton';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useGame } from '../../store/game';
import { GameRulesModal } from '../game/GameRulesModal';

export default function JoinableLobby() {
  const { players, gameState, room, self } = useGame((state) => ({
    players: state.players,
    gameState: state.gameState,
    room: state.room,
    self: state.self,
  }));
  const { color } = useTokens();
  const [gameRulesModalOpen, setGameRulesModalOpen] = useState(false);

  const hasEnoughPlayers = players.length > 1;
  const allPlayersReady = players.every(
    (player) => player.host || player.ready
  );

  return (
    <View>
      <GameRulesModal
        open={gameRulesModalOpen}
        onClose={() => setGameRulesModalOpen(false)}
      />
      <SizableText size="$6" style={{ alignSelf: 'center' }}>
        Room code: <Text fontWeight="bold">{gameState.roomCode}</Text>
      </SizableText>
      <Separator marginVertical="$space.3" />
      <YStack gap="$2">
        {players.map((player) => (
          <XStack gap="$3" key={player.sessionId}>
            <View width="$5" height="$5" style={{ position: 'relative' }}>
              <PlayerAvatar
                size="sm"
                avatar={player.avatar || DEFAULT_FALLBACK_AVATAR}
                color={player.avatarColor || DEFAULT_FALLBACK_AVATAR_COLOR}
                style={{ position: 'absolute', bottom: 0, left: 0 }}
              />
              <Circle
                style={{ position: 'absolute', top: 0, right: 0 }}
                size="$1"
                backgroundColor={
                  player.host || player.ready
                    ? color.success60.val
                    : color.info80.val
                }
              />
            </View>
            <Text style={{ alignSelf: 'center' }}>{player.username}</Text>
          </XStack>
        ))}
      </YStack>

      <Separator marginVertical="$space.3" />

      {self?.host && (
        <YStack>
          <XStack alignSelf="flex-end" gap="$2">
            <StyledButton
              icon={<MaterialCommunityIcons name="cog-outline" size={16} />}
              theme="secondary"
              onPress={() => setGameRulesModalOpen(true)}
            />
            <StyledButton
              icon={<MaterialCommunityIcons name="play" />}
              disabled={!hasEnoughPlayers || !allPlayersReady}
              onPress={() => room.send('startGame', true)}
            >
              Start game
            </StyledButton>
          </XStack>
          {!hasEnoughPlayers && (
            <Paragraph style={styles.helperText}>
              Two or more players required to start.
            </Paragraph>
          )}
          {!allPlayersReady && (
            <Paragraph style={styles.helperText}>
              All players must be ready.
            </Paragraph>
          )}
        </YStack>
      )}
      {!self?.host && (
        <XStack alignSelf="flex-end" gap="$2">
          <StyledButton
            icon={<MaterialCommunityIcons name="check" />}
            disabled={self?.ready}
            onPress={() => room.send('ready', true)}
          >
            I'm ready
          </StyledButton>
        </XStack>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  helperText: {
    opacity: 0.6,
  },
});

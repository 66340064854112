import { G, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsFiveVentsMouth() {
  return (
    <G transform="translate(22 68)">
      <G fill="#000" fillOpacity=".6">
        <Rect x="12" y="12" width="4" height="8" rx="2" />
        <Rect x="36" y="12" width="4" height="8" rx="2" />
        <Rect x="24" y="12" width="4" height="8" rx="2" />
        <Rect x="48" y="12" width="4" height="8" rx="2" />
        <Rect x="60" y="12" width="4" height="8" rx="2" />
      </G>
    </G>
  );
}

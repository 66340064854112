import { appColors } from './tokens/colors';
import { ColorPalette } from './tokens/helpers';

type MaterialColorKeys<T extends string> =
  | T
  | `${T}Press`
  | `on${Capitalize<T>}`
  | `${T}Container`
  | `${T}ContainerPress`
  | `on${Capitalize<T>}Container`;

export type MaterialYouTheme<T extends string> = {
  [key in MaterialColorKeys<T>]: string;
};

export function generateLightTheme<T extends string>(
  name: T,
  palette: ColorPalette
): MaterialYouTheme<T> {
  let Name = `${name.charAt(0).toUpperCase()}${name.slice(1)}` as Capitalize<T>;

  return {
    [name]: palette[40],
    [`${name}Press`]: palette[30],
    [`on${Name}`]: appColors.backgroundLight,
    [`${name}Container`]: palette[90],
    [`${name}ContainerPress`]: palette[80],
    [`on${Name}Container`]: palette[10],
  } as MaterialYouTheme<T>;
}

export function generateDarkTheme<T extends string>(
  name: T,
  palette: ColorPalette
): MaterialYouTheme<T> {
  let Name = `${name.charAt(0).toUpperCase()}${name.slice(1)}` as Capitalize<T>;

  return {
    [name]: palette[80],
    [`${name}Press`]: palette[70],
    [`on${Name}`]: palette[20],
    [`${name}Container`]: palette[30],
    [`${name}ContainerPress`]: palette[20],
    [`on${Name}Container`]: palette[90],
  } as MaterialYouTheme<T>;
}

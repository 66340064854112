import { G, Rect } from 'react-native-svg';
import React from 'react';

export function BotttsRoundVisorEyes() {
  return (
    <G transform="translate(8 20)">
      <Rect
        y="12"
        width="104"
        height="32"
        rx="16"
        fill="#000"
        fillOpacity=".8"
      />
      <Rect x="24" y="22" width="10" height="12" rx="2" fill="#F4F4F4" />
      <Rect x="70" y="22" width="10" height="12" rx="2" fill="#F4F4F4" />
    </G>
  );
}

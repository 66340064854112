import React, { useEffect, useState } from 'react';
import { Separator, YStack } from 'tamagui';
import { AvatarKey } from '../../avatars';
import { YStackProps } from 'tamagui';
import { ColorRadioGroup } from './ColorRadioGroup';
import { AvatarRadioGroup } from './AvatarRadioGroup';
import { AvatarSetDetails } from '../../@types/avatars';

type Props<T extends string> = {
  set: AvatarSetDetails<T>;
  currentAvatar: string;
  currentColor: string;
  onChangeAvatar?: (avatarKey: AvatarKey) => void;
  onChangeColor?: (avatarColor: string) => void;
} & YStackProps;

export function AvatarSetGroup<T extends string>({
  set,
  currentAvatar,
  currentColor,
  onChangeAvatar,
  onChangeColor,
  ...props
}: Props<T>) {
  const [workingColor, setWorkingColor] = useState(currentColor);
  const [workingAvatar, setWorkingAvatar] = useState(currentAvatar);

  useEffect(() => {
    if (
      !set.colorOptions.some(
        (colorOption) => colorOption.value === currentColor
      )
    ) {
      setWorkingColor(set.colorOptions[0].value);
    }
    if (!(currentAvatar in set.avatars)) {
      setWorkingAvatar(set.avatarList[0].key);
    }
  }, [currentColor, currentAvatar]);

  const showSelected = currentAvatar in set.avatars;

  return (
    <YStack gap="$2" {...props}>
      {set.colorOptions && (
        <>
          <ColorRadioGroup
            value={workingColor}
            values={set.colorOptions}
            onChangeColor={(newColor) => {
              setWorkingColor(newColor);
              onChangeColor && onChangeColor(newColor);
              onChangeAvatar && onChangeAvatar(workingAvatar as AvatarKey);
            }}
            showSelected={showSelected}
            style={{ alignSelf: 'center' }}
          />
          <Separator />
        </>
      )}
      <AvatarRadioGroup
        value={workingAvatar}
        values={set.avatarList}
        color={workingColor}
        onChangeAvatar={(newAvatar) => {
          setWorkingAvatar(newAvatar);
          onChangeColor && onChangeColor(workingColor);
          onChangeAvatar && onChangeAvatar(newAvatar);
        }}
        showSelected={showSelected}
        style={{ alignSelf: 'center' }}
      />
    </YStack>
  );
}

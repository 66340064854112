import React from 'react';
import { FacesNormalEyes } from './eyes/normal';
import { FacesFrownMouth } from './mouths/frown';
import { FacesBase } from './base';

export function FacesSadGuy() {
  return (
    <FacesBase>
      <FacesNormalEyes />
      <FacesFrownMouth />
    </FacesBase>
  );
}

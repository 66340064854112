import { BotttsBase } from './base';
import { BotttsSquareVisorEyes } from './eyes/square-visor';
import { BotttsLCDMouth } from './mouths/lcd';
import React from 'react';

export function BotttsHarley() {
  return (
    <BotttsBase>
      <BotttsSquareVisorEyes />
      <BotttsLCDMouth />
    </BotttsBase>
  );
}

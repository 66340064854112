import { StyleSheet, View } from 'react-native';
import { Button, Spinner } from 'tamagui';
import { EditAvatarButton } from '../components/home/EditAvatarButton';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import React, { useState } from 'react';
import Logo from '../components/home/Logo';
import { EditProfileModal } from '../components/home/EditProfileModal';
import colyseus from '../providers/colyseus';
import BaseScreen from './BaseScreen';
import { useNavigation } from '@react-navigation/native';
import {
  DEFAULT_FALLBACK_AVATAR,
  DEFAULT_FALLBACK_AVATAR_COLOR,
} from '../utils/avatar';
import { JoinGameModal } from '../components/home/JoinGameModal';
import { useProfile } from '../store/prefs';

export default function MainScreen() {
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [joinGameModalOpen, setJoinGameModalOpen] = useState(false);
  const [hostGameLoading, setHostGameLoading] = useState(false);

  const profile = useProfile();
  const navigation = useNavigation();

  const hostGame = () => {
    if (hostGameLoading) {
      return;
    }

    setHostGameLoading(true);
    colyseus
      .createLobby()
      .then((room) => {
        navigation.navigate('Lobby', {
          lobbyId: room.roomId,
        });
      })
      .finally(() => {
        setHostGameLoading(false);
      });
  };

  const joinGame = () => {
    setJoinGameModalOpen(true);
  };

  return (
    <BaseScreen>
      <Logo />

      <View style={{ marginTop: 12, marginBottom: 12 }}>
        <EditAvatarButton
          avatar={profile.avatar || DEFAULT_FALLBACK_AVATAR}
          avatarColor={profile.avatarColor || DEFAULT_FALLBACK_AVATAR_COLOR}
          onPress={() => {
            setProfileModalOpen(true);
          }}
        />
      </View>

      <View style={styles.buttonContainer}>
        <Button
          icon={
            hostGameLoading ? (
              <Spinner />
            ) : (
              <MaterialCommunityIcons name="plus" size={18} />
            )
          }
          alignSelf="center"
          size="$5"
          onPress={hostGame}
        >
          Host game
        </Button>
        <Button
          icon={<MaterialCommunityIcons name="play" size={18} />}
          alignSelf="center"
          size="$5"
          onPress={joinGame}
        >
          Join game
        </Button>
      </View>
      <EditProfileModal
        open={profileModalOpen}
        onClose={() => {
          setProfileModalOpen(false);
        }}
      />
      <JoinGameModal
        open={joinGameModalOpen}
        onClose={() => {
          setJoinGameModalOpen(false);
        }}
      />
    </BaseScreen>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    gap: 4,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '75%',
    justifyContent: 'center',
  },
});

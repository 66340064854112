import { StyleSheet } from 'react-native';
import { Card, Paragraph, Text, ThemeName, XStack, useTheme } from 'tamagui';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import React from 'react';

export type ToastProps = {
  title?: string;
  message: string;
  icon?: string;
  theme?: 'success' | 'info' | 'warning';
};

export default function Toast(props: ToastProps) {
  const theme = useTheme({
    name: props.theme as ThemeName,
    componentName: 'Card',
  });

  return (
    <Card size="$3" bordered theme={props.theme as ThemeName}>
      <XStack>
        {props.icon && (
          <MaterialCommunityIcons
            name={props.icon}
            size={props.title ? 32 : 24}
            color={theme.color?.val}
            style={styles.icon}
          />
        )}
        <Card.Header padded>
          {props.title && (
            <Text style={{ fontWeight: 'bold' }}>{props.title}</Text>
          )}
          <Paragraph color={theme.color?.val}>{props.message}</Paragraph>
        </Card.Header>
      </XStack>
    </Card>
  );
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

import { BotttsBase } from './base';
import { BotttsRoundVisorEyes } from './eyes/round-visor';
import { BotttsSmileMouth } from './mouths/smile';
import React from 'react';

export function BotttsRalphie() {
  return (
    <BotttsBase>
      <BotttsRoundVisorEyes />
      <BotttsSmileMouth />
    </BotttsBase>
  );
}

import { TamaguiProvider } from 'tamagui';
import { StatusBar } from 'expo-status-bar';
import { useColorScheme } from 'react-native';
import { toastConfig } from './theme/toasts';
import React from 'react';
import config from '../tamagui.config';
import MainScreen from './screens/MainScreen';
import Toast from 'react-native-toast-message';
import { NavigationContainer } from '@react-navigation/native';
import {
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';

import * as Linking from 'expo-linking';

import LobbyScreen from './screens/LobbyScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import { usePrefs } from './store/prefs';

const APP_NAME = 'House Rules';

const prefix = Linking.createURL('/');

const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      Home: '',
      Lobby: 'lobby/:lobbyId',
      NotFound: '*',
    },
  },
};

export type RootStackParamList = {
  Home: undefined;
  Lobby: { lobbyId: string };
  NotFound: undefined;
};

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  StackScreenProps<RootStackParamList, T>;

const Stack = createStackNavigator<RootStackParamList>();

export default function ScreenRouter() {
  let themeName = usePrefs((state) => state.theme);
  if (themeName === 'auto') {
    themeName = useColorScheme() || 'dark';
  }
  const statusBarStyle = themeName === 'dark' ? 'light' : 'dark';

  return (
    <TamaguiProvider config={config} defaultTheme={themeName}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen
            name="Home"
            component={MainScreen}
            options={{ title: generateTitle() }}
          />
          <Stack.Screen
            name="Lobby"
            component={LobbyScreen}
            options={{ title: generateTitle('Lobby') }}
          />
          <Stack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{ title: generateTitle('Page not found') }}
          />
        </Stack.Navigator>
      </NavigationContainer>
      <StatusBar style={statusBarStyle} />
      <Toast config={toastConfig} />
    </TamaguiProvider>
  );
}

const generateTitle = (pageName?: string) => {
  if (pageName) {
    return `${pageName} - ${APP_NAME}`;
  }
  return APP_NAME;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

import React, { useEffect, useState } from 'react';
import { YStack, XStack, Label, Switch } from 'tamagui';
import { Modal } from '../core/Modal';
import { StyledButton } from '../core/StyledButton';
import { useGame } from '../../store/game';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { GameRules } from '../../utils/game-types';
import NumericInput from '../core/NumericInput';

type Props = {
  open: boolean;
  onClose: () => void;
};

export function GameRulesModal({ open, onClose }: Props) {
  const { gameState, room } = useGame((state) => ({
    gameState: state.gameState,
    room: state.room,
  }));

  const [startCards, setStartCards] = useState(1);
  const [maxCards, setMaxCards] = useState(1);
  const [numOfDecks, setNumOfDecks] = useState(1);
  const [notifyOnTurn, setNotifyOnTurn] = useState(false);

  useEffect(() => {
    if (!gameState.rules) {
      return;
    }

    setStartCards(gameState.rules.startCards);
    setMaxCards(gameState.rules.maxCards);
    setNumOfDecks(gameState.rules.numOfDecks);
    setNotifyOnTurn(gameState.rules.notifyOnTurn);
  }, [open]);

  const onModalClose = () => {
    onClose();
  };

  const saveSelections = () => {
    room.send('changeRule', {
      startCards,
      maxCards,
      numOfDecks,
      notifyOnTurn,
    } as Partial<GameRules>);

    onModalClose();
  };

  return (
    <Modal
      open={open}
      title="Edit game rules"
      dismissible
      maxWidth="sm"
      buttons={
        <>
          <StyledButton onPress={onModalClose} theme="secondary">
            Cancel
          </StyledButton>
          <StyledButton
            icon={<MaterialCommunityIcon name="check" />}
            disabled={!startCards || !maxCards || !numOfDecks}
            onPress={saveSelections}
          >
            Save
          </StyledButton>
        </>
      }
      onClose={onModalClose}
    >
      <YStack padding="$3" minWidth={300} gap="$4">
        <XStack alignItems="center" gap="$4">
          <Label width={120} htmlFor="start-hand-size">
            Start hand size
          </Label>
          <NumericInput
            flex={1}
            id="start-hand-size"
            value={startCards}
            onChangeValue={setStartCards}
          />
        </XStack>

        <XStack alignItems="center" gap="$4">
          <Label width={120} htmlFor="max-hand-size">
            Max hand size
          </Label>
          <NumericInput
            flex={1}
            id="max-hand-size"
            value={maxCards}
            onChangeValue={setMaxCards}
          />
        </XStack>

        <XStack alignItems="center" gap="$4">
          <Label width={120} htmlFor="num-of-decks">
            Number of decks
          </Label>
          <NumericInput
            flex={1}
            id="num-of-decks"
            value={numOfDecks}
            onChangeValue={setNumOfDecks}
          />
        </XStack>

        <XStack alignItems="center" gap="$4">
          <Label width={120} htmlFor="notify">
            Turn notifications
          </Label>
          <Switch
            id="notify"
            checked={notifyOnTurn}
            onCheckedChange={setNotifyOnTurn}
          >
            <Switch.Thumb animation="quick" />
          </Switch>
        </XStack>
      </YStack>
    </Modal>
  );
}

import { Path, G } from 'react-native-svg';
import React from 'react';

export function FacesNormalEyes() {
  return (
    <G transform="matrix(1.5625 0 0 1.5625 31.25 59.38)">
      <Path
        d="M87 13.04C87 5.84 81.18 0 74 0S61 5.84 61 13.04v3.92C61 24.16 66.82 30 74 30s13-5.84 13-13.04v-3.92Z"
        fill="#000"
      />
      <Path
        d="M69.79 9.53a2.29 2.29 0 1 0 0-4.57 2.29 2.29 0 0 0 0 4.57Z"
        fill="#fff"
      />
      <Path
        opacity=".1"
        d="M74.02 18.35a5.32 5.32 0 1 0 0-10.65 5.32 5.32 0 0 0 0 10.65Z"
        fill="#fff"
      />
      <Path
        d="M26 13.04C26 5.84 20.18 0 13 0S0 5.84 0 13.04v3.92C0 24.16 5.82 30 13 30s13-5.84 13-13.04v-3.92Z"
        fill="#000"
      />
      <Path
        d="M8.79 9.53a2.29 2.29 0 1 0 0-4.57 2.29 2.29 0 0 0 0 4.57Z"
        fill="#fff"
      />
      <Path
        opacity=".1"
        d="M13.02 18.35a5.32 5.32 0 1 0 0-10.65 5.32 5.32 0 0 0 0 10.65Z"
        fill="#fff"
      />
    </G>
  );
}

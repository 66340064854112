import { Spinner } from 'tamagui';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { RootStackScreenProps } from '../ScreenRouter';
import BaseScreen from './BaseScreen';
import colyseus from '../providers/colyseus';
import React, { useCallback, useEffect, useState } from 'react';
// import { setGameRoom, setGameState } from '../store/slices/game';
import JoinableLobby from '../components/lobby/JoinableLobby';
import StartedLobby from '../components/lobby/StartedLobby';
import { useProfile } from '../store/prefs';
import { useGame } from '../store/game';

export default function LobbyScreen() {
  const route = useRoute<RootStackScreenProps<'Lobby'>['route']>();
  const navigation = useNavigation();
  const profile = useProfile();

  const { setGameRoom } = useGame((state) => ({
    setGameRoom: state.setRoom,
  }));

  const [loading, setLoading] = useState(true);
  const [hasStarted, setHasStarted] = useState(false);
  const [hasError, setHasError] = useState(false);

  const clearState = () => {
    setLoading(true);
    setHasStarted(false);
    setHasError(false);
    setGameRoom({} as any);
  };

  useEffect(() => {
    if (route.params.lobbyId) {
      colyseus
        .joinMatchById(profile, route.params.lobbyId)
        .then((room) => {
          setGameRoom(room);
          room.onMessage('gameStarted', () => {
            setHasStarted(true);
          });
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [route]);

  useFocusEffect(
    useCallback(() => {
      // reset state on screen close
      return clearState;
    }, [])
  );

  if (hasError) {
    navigation.navigate('Home');
  }

  return (
    <BaseScreen header>
      {loading && <Spinner size="large" color="$primary" />}
      {!loading && !hasStarted && <JoinableLobby />}
      {!loading && hasStarted && <StartedLobby />}
    </BaseScreen>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Fieldset,
  Label,
  Input,
  Tabs,
  Text,
  Separator,
  TabsContentProps,
} from 'tamagui';
import { Modal } from '../core/Modal';
import { StyledButton } from '../core/StyledButton';
import { useProfile, useProfileSetters } from '../../store/prefs';
import { PlayerAvatar } from '../core/PlayerAvatar';
import { BotttsSet } from '../../avatars/bottts';
import { AvatarSetGroup } from '../profile/AvatarSetGroup';
import { FacesSet } from '../../avatars/faces';
import { AvatarSetDetails } from '../../@types/avatars';

type Props = {
  open: boolean;
  onClose: () => void;
};

type AvatarDisplaySet = {
  key: string;
  name: string;
  set: AvatarSetDetails<string>;
};

const DISPLAY_AVATAR_SETS: AvatarDisplaySet[] = [
  {
    key: 'avatar-bottts',
    name: 'Robots',
    set: BotttsSet,
  },
  {
    key: 'avatar-faces',
    name: 'Faces',
    set: FacesSet,
  },
];

export function EditProfileModal({ open, onClose }: Props) {
  const {
    username: currentUsername,
    avatar: currentAvatar,
    avatarColor: currentAvatarColor,
  } = useProfile();
  const { setUsername, setAvatar, setAvatarColor } = useProfileSetters();

  const [workingUsername, setWorkingUsername] = useState(currentUsername);
  const [workingAvatar, setWorkingAvatar] = useState(currentAvatar);
  const [workingAvatarColor, setWorkingAvatarColor] =
    useState(currentAvatarColor);

  let defaultAvatarSet =
    DISPLAY_AVATAR_SETS.find((avatarSet) =>
      avatarSet.set?.avatarList.some((avatar) => avatar.key === currentAvatar)
    )?.key || DISPLAY_AVATAR_SETS[0].key;

  const saveChanges = () => {
    setUsername(workingUsername.trim());
    setAvatar(workingAvatar);
    setAvatarColor(workingAvatarColor);

    onClose();
  };

  const onModalClose = () => {
    // reset unsaved preferences to their defaults
    setWorkingUsername(currentUsername);
    setWorkingAvatar(currentAvatar);
    setWorkingAvatarColor(currentAvatarColor);

    onClose();
  };

  return (
    <Modal
      open={open}
      title="Edit profile"
      description="Edit your username and avatar here, and click save when you're done."
      dismissible
      scrollable
      maxWidth="sm"
      buttons={
        <>
          <StyledButton theme="secondary" onPress={onModalClose}>
            Cancel
          </StyledButton>
          <StyledButton
            disabled={!workingUsername.length}
            onPress={saveChanges}
          >
            Save changes
          </StyledButton>
        </>
      }
      onClose={onModalClose}
    >
      <Fieldset gap="$4" horizontal>
        <Label width={120} justifyContent="flex-end" htmlFor="username">
          Username
        </Label>
        <Input
          flex={1}
          id="username"
          value={workingUsername}
          onChangeText={setWorkingUsername}
        />
      </Fieldset>
      <Separator />
      <PlayerAvatar
        size="lg"
        avatar={workingAvatar}
        color={workingAvatarColor}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      />
      <Tabs
        defaultValue={defaultAvatarSet}
        flexDirection="row"
        orientation="vertical"
        width="100%"
        borderStyle="solid"
        borderRadius="$4"
        borderWidth="$1"
        overflow="hidden"
        borderColor="$borderColor"
      >
        <Tabs.List
          disablePassBorderRadius="end"
          aria-label="Edit your avatar"
          separator={<Separator />}
        >
          {DISPLAY_AVATAR_SETS.map((avatarSetOption) => (
            <Tabs.Tab
              value={avatarSetOption.key}
              key={`tab-${avatarSetOption.key}`}
              borderWidth={0}
            >
              <Text>{avatarSetOption.name}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Separator vertical />
        {DISPLAY_AVATAR_SETS.map((avatarSetOption) => (
          <TabContent
            value={avatarSetOption.key}
            key={`content-${avatarSetOption.key}`}
          >
            <AvatarSetGroup
              set={avatarSetOption.set}
              currentAvatar={workingAvatar}
              currentColor={workingAvatarColor}
              onChangeAvatar={setWorkingAvatar}
              onChangeColor={setWorkingAvatarColor}
            />
          </TabContent>
        ))}
      </Tabs>
    </Modal>
  );
}

const TabContent = (props: TabsContentProps) => {
  return (
    <Tabs.Content
      backgroundColor="$background"
      key="tab3"
      padding="$2"
      alignItems="center"
      justifyContent="center"
      flex={1}
      borderColor="$background"
      borderRadius="$2"
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      borderWidth="$2"
      {...props}
    >
      {props.children}
    </Tabs.Content>
  );
};

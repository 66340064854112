export type PaletteIndices = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 95;
export type ColorPalette = {
  [key in PaletteIndices]: string;
};
export type KeyedColorPalette<T extends string> = {
  [K in keyof ColorPalette as `${T}${K}`]: string;
};

export function generatePalette<T extends string>(
  name: T,
  colors: ColorPalette
): KeyedColorPalette<T> {
  let palette = {} as any;

  for (let index in colors) {
    palette[`${name}${index}`] = colors[Number(index) as PaletteIndices];
  }

  return palette;
}

import { Path, G } from 'react-native-svg';
import React from 'react';

export function FacesClosedEyes() {
  return (
    <G transform="matrix(1.5625 0 0 1.5625 31.25 59.38)">
      <Path
        d="M63.16 19.11a1.68 1.68 0 0 1-1.5-.93 1.75 1.75 0 0 1-.12-1.32 13.54 13.54 0 0 1 4.85-7.1 13.1 13.1 0 0 1 8.07-2.65v.01c2.9-.01 5.73.92 8.06 2.68a13.63 13.63 0 0 1 4.87 7.08c.13.44.08.91-.13 1.32a1.72 1.72 0 0 1-2.3.73 1.69 1.69 0 0 1-.83-1.03c-.63-2.13-1.91-4-3.66-5.32a9.92 9.92 0 0 0-6.07-2c-2.17 0-4.28.7-6.02 2.02a10.26 10.26 0 0 0-3.64 5.3c-.1.35-.32.65-.6.87a1.7 1.7 0 0 1-.98.36ZM2.17 19.11a1.4 1.4 0 0 1-.5-.08 1.68 1.68 0 0 1-1.01-.85 1.75 1.75 0 0 1-.12-1.32 13.57 13.57 0 0 1 4.85-7.1 13.12 13.12 0 0 1 8.07-2.65v.01c2.9-.02 5.73.92 8.06 2.67a13.6 13.6 0 0 1 4.86 7.09 1.75 1.75 0 0 1-.53 1.85c-.18.14-.38.25-.6.32-.42.13-.9.09-1.3-.12-.4-.22-.7-.59-.83-1.03-.62-2.13-1.9-4-3.65-5.31a9.92 9.92 0 0 0-6.06-2.01 9.92 9.92 0 0 0-6.03 2.02 10.26 10.26 0 0 0-3.64 5.3c-.1.35-.31.65-.6.87-.27.22-.61.35-.97.36Z"
        fill="#000"
      />
    </G>
  );
}

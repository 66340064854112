import React from 'react';
import { Text, Tooltip, View, ViewProps } from 'tamagui';
import { useGame } from '../../store/game';
import { PlayerAvatar } from '../core/PlayerAvatar';
import GradientScrollView from '../core/GradientScrollView';

export function PlayerCarousel(props: ViewProps) {
  const { currentPlayer, players, self, hands } = useGame((state) => ({
    currentPlayer: state.gameState?.currentPlayer,
    players: state.players,
    self: state.self,
    hands: state.gameState?.hands,
  }));

  const selectedProps: Partial<ViewProps> = {
    outlineColor: '$onBackground',
    outlineWidth: '$1',
    outlineStyle: 'solid',
    outlineOffset: '$1',
  };

  return (
    <GradientScrollView horizontal gap="$4" {...props}>
      {players.map((player) => (
        <View key={player.sessionId}>
          <Tooltip placement="bottom" delay={0} restMs={0}>
            <Tooltip.Trigger>
              <PlayerAvatar
                size="sm"
                avatar={player.avatar}
                color={player.avatarColor}
                {...(player.sessionId === currentPlayer ? selectedProps : {})}
                margin="$2"
              />
            </Tooltip.Trigger>
            <Tooltip.Content
              enterStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
              exitStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
              scale={1}
              x={0}
              y={0}
              opacity={1}
              animation={[
                'quick',
                {
                  opacity: {
                    overshootClamping: true,
                  },
                },
              ]}
            >
              <Tooltip.Arrow />
              <Text>
                {self?.sessionId === player.sessionId ? 'You' : player.username}
              </Text>
              <Text>Cards: {hands.get(player.sessionId)?.size}</Text>
            </Tooltip.Content>
          </Tooltip>
        </View>
      ))}
    </GradientScrollView>
  );
}
